import { FC, lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Loader } from 'shared/elements/Loader';
import css from './App.module.css';
import AttackMatrix from './AttackMatrix/AttackMatrixContainer';
import { Nav } from './Nav';
import FreeTrial from './FreeTrial';
import { useUserContext } from 'shared/user/UserContext';

const NotFound = lazy(() => import('./NotFound'));
const Dashboard = lazy(() => import('./Dashboard'));
const Settings = lazy(() => import('./Settings'));
const Techniques = lazy(() => import('./Techniques'));
const TechniquesDetail = lazy(() => import('./Techniques/TechniquesDetail'));
const Assets = lazy(() => import('./Assets'));
const AssetsDetail = lazy(() => import('./Assets/AssetsDetail'));
const ThreatGroups = lazy(() => import('./ThreatGroups'));
const ThreatGroupsDetail = lazy(
  () => import('./ThreatGroups/ThreatGroupsDetail')
);
const Campaigns = lazy(() => import('./Campaigns'));
const CampaignsDetail = lazy(() => import('./Campaigns/CampaignsDetail'));
const Softwares = lazy(() => import('./Softwares'));
const SoftwareDetail = lazy(() => import('./Softwares/SoftwareDetail'));
const Visibility = lazy(() => import('./Visibility'));
const VisibilityDetail = lazy(() => import('./Visibility/VisibilityDetail'));
const Detections = lazy(() => import('./Detections'));
const DetectionsDetail = lazy(() => import('./Detections/DetectionsDetail'));
const Identities = lazy(() => import('./Identities/Identities'));
const IdentityDetail = lazy(
  () => import('./Identities/detail/IdentityDetailContainer')
);
const ThreatProfiles = lazy(() => import('./Settings/ThreatProfiles'));
const Integrations = lazy(() => import('./Settings/Integrations'));
const IntegrationDetail = lazy(
  () => import('./Settings/Integrations/IntegrationDetail')
);
const Vulnerability = lazy(() => import('./Vulnerability'));
const VulnerabilityDetail = lazy(
  () => import('./Vulnerability/VulnerabilityDetail')
);
const Controls = lazy(() => import('./Controls'));
const ControlsDetail = lazy(() => import('./Controls/ControlsDetail'));
const ProductComparison = lazy(() => import('./ProductComparison'));
const Logout = lazy(() => import('./Settings/Logout'));
const AIChatConversation = lazy(() => import('./AIChat/AIChatConversation'));
const AIChatWelcome = lazy(() => import('./AIChat/AIChatWelcome'));

export const App: FC = () => {
  const { isAuthenticated } = useUserContext();

  return (
    <div className={css.app}>
      {isAuthenticated && <Nav />}

      <div className={css.content}>
        <Suspense fallback={<Loader />}>
          {/* This could really go anywhere */}
          <Routes>
            {/* Public Routes */}
            {!isAuthenticated && (
              <Route path="/free-trial" element={<FreeTrial />} />
            )}

            {/* Dashboard (Root) */}
            <Route path="ciso" element={<Dashboard />} />

            {/* Threat Exposure */}
            <Route path="campaigns" element={<Campaigns />} />
            <Route path="campaigns/:id" element={<CampaignsDetail />} />
            <Route path="techniques/:id" element={<TechniquesDetail />} />
            <Route path="techniques" element={<Techniques />} />
            <Route path="software/*" element={<Softwares />} />
            <Route path="software/:id" element={<SoftwareDetail />} />
            <Route path="threat-groups/*" element={<ThreatGroups />} />
            <Route path="/attack-matrix" element={<AttackMatrix />} />
            <Route path="threat-groups/:id" element={<ThreatGroupsDetail />} />
            <Route path="threat-profiles/:tab?" element={<ThreatProfiles />} />

            {/* Defense Surface */}
            <Route path="visibility/:id" element={<VisibilityDetail />} />
            <Route path="visibility" element={<Visibility />} />
            <Route path="detections/*" element={<Detections />} />
            <Route path="detections/:id" element={<DetectionsDetail />} />
            <Route path="identities/*" element={<Identities />} />
            <Route path="identities/:id" element={<IdentityDetail />} />
            <Route path="integrations/:tab?" element={<Integrations />} />
            <Route
              path="integration/:id/:tab?"
              element={<IntegrationDetail />}
            />
            <Route path="product-comparison" element={<ProductComparison />} />

            {/* Fleet Exposure */}
            <Route
              path="recommendations/vulnerabilities/:id"
              element={<VulnerabilityDetail />}
            />
            <Route path="vulnerabilities/*" element={<Vulnerability />} />
            <Route
              path="vulnerabilities/:id"
              element={<VulnerabilityDetail />}
            />
            <Route path="asset/:id" element={<AssetsDetail />} />
            <Route path="asset" element={<Assets />} />

            {/* AI Chat */}
            <Route path="aichat/welcome" element={<AIChatWelcome />} />
            <Route
              path="aichat/conversation"
              element={<AIChatConversation />}
            />

            {/* Settings */}
            <Route path="settings/*" element={<Settings />} />

            {/* Redirects */}
            <Route path="/" element={<Navigate to="ciso" replace />} />

            {/* Special Pages */}
            <Route path="/auth/logout" element={<Logout />} />
            <Route path="404" element={<NotFound />} />
            <Route path="*" element={<Navigate to="/404" replace />} />

            {/* Nested */}
            <Route
              path="recommendations/visibility/:id"
              element={<VisibilityDetail />}
            />
            <Route
              path="recommendations/techniques/:id"
              element={<TechniquesDetail />}
            />
            <Route
              path="recommendations/software/:id"
              element={<SoftwareDetail />}
            />
            <Route
              path="recommendations/threat-groups/:id"
              element={<ThreatGroupsDetail />}
            />
            <Route
              path="recommendations/controls/:id"
              element={<ControlsDetail />}
            />
            <Route path="controls/*" element={<Controls />} />
            <Route path="controls/:id" element={<ControlsDetail />} />
          </Routes>
        </Suspense>
      </div>
    </div>
  );
};
