import { yupResolver } from '@hookform/resolvers/yup';
import { ReactComponent as LogoIcon } from 'assets/brand/icon-white.svg';
import { ReactComponent as RedirectIcon } from 'assets/icons/redirect.svg';
import { Block, Button, Divider, Input } from 'reablocks';
import { FC, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import css from './FreeTrial.module.css';

const personalDomains = [
  'gmail.com',
  'yahoo.com',
  'hotmail.com',
  'outlook.com',
  'aol.com',
  'icloud.com',
  'protonmail.com',
  'mail.com',
  'zoho.com',
  'yandex.com',
  'gmx.com',
  'live.com',
  'msn.com',
  'me.com',
  'inbox.com',
  '10minutemail.com',
  'temp-mail.org',
  'guerrillamail.com',
  'mailinator.com',
  'yopmail.com',
  'tempmail.net',
  'sharklasers.com',
  'dispostable.com',
  'fakeinbox.com',
  'throwawaymail.com',
  'test.com'
];

const checkIfFakeDomain = async domain => {
  try {
    const response = await fetch(
      `https://fakefilter.net/api/is/fakedomain/${domain}`
    );

    if (!response.ok) {
      // Fail open - if we get an error, assume the domain is real
      console.warn(
        `FakeFilter API error: ${response.status} for domain ${domain}`
      );
      return false;
    }

    const data = await response.json();
    return data.isFakeDomain !== false;
  } catch (error) {
    // Fail open - if there's an error with the request, assume domain is real
    console.error(`Error checking domain ${domain}:`, error);
    return false;
  }
};

const credentialsSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Required')
    .test(
      'is-real-email',
      'Please use your work email',
      async function (value) {
        if (!value) return true;

        // First check that is is a valid email
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(value)) {
          return true;
        }

        const domain = value.split('@')[1];

        // Check if domain is in personal domains list
        if (personalDomains.includes(domain)) {
          return false;
        }

        // Only call FakeFilter API if we have a valid email format
        const isFake = await checkIfFakeDomain(domain);
        if (isFake) {
          return false;
        }

        return true;
      }
    ),
  fullName: Yup.string().required('Required')
});

interface FreeTrialProps {
  onDiscover: (value: { email: string; fullName: string }) => void;
}
/**
 * A "domain registration" represents a single tenancy of a user.  For instance,
 * if a user belonged to organization A and B, the API would return two
 * registrations for them (one for org A and one for B).
 */
export interface DomainRegistration {
  client_id: string;
  tenant_id: string;
  tenant_name: string;
}
/** Gets the possible tenants for the provided email address */
export const fetchTenants = async (
  email: string
): Promise<DomainRegistration[]> => {
  const response = await fetch('/auth/v2/client', {
    body: JSON.stringify({ email }),
    method: 'POST'
  });
  const { domain_registrations } = await response.json();
  return domain_registrations;
};

const errorCodes = {
  '1': 'Your IP address is not on the tenant allow list. Please contact support.'
};

const FreeTrial: FC<FreeTrialProps> = ({ onDiscover }) => {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid }
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(credentialsSchema),
    defaultValues: {
      email: '',
      fullName: ''
    }
  });

  type FormData = {
    email: string;
    fullName: string;
  };

  const [isSubmitted, setIsSubmitted] = useState(false);

  // Use React Hook Form's built-in isSubmitting state instead of managing our own loading state
  const onSubmit = async (data: FormData) => {
    try {
      const response = await fetch('/auth/free-trial', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: data.email,
          full_name: data.fullName
        })
      });

      if (!response.ok) {
        throw new Error('Failed to submit');
      }

      onDiscover(data);
      setIsSubmitted(true);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <>
      <Helmet>
        <title>FreeTrial</title>
      </Helmet>
      <div className={css.container}>
        <div className={css.left}>
          {!isSubmitted ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={css.login}>
                <LogoIcon className={css.logo} />
                <h1>Welcome to Interpres</h1>
                <p className={css.tagline}>
                  Begin prioritizing the threats that matter most! Sign up for a
                  free 30 day trial today.
                </p>
                <Divider />
                <Block label="Name" className="w-[90%]">
                  <Controller
                    name="fullName"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <>
                        <Input {...field} type="text" />
                        {error && (
                          <div className="mt-1 text-sm text-red-500">
                            {error.message}
                          </div>
                        )}
                      </>
                    )}
                  />
                </Block>
                <Block label="Email" className="w-[90%]">
                  <Controller
                    name="email"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <>
                        <Input {...field} type="email" />
                        {error && (
                          <div className="mt-1 text-sm text-red-500">
                            {error.message}
                          </div>
                        )}
                      </>
                    )}
                  />
                </Block>
                <Button
                  type="submit"
                  color="primary"
                  className="mt-8"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Submitting...' : 'Request Free Trial'}
                </Button>
                <br />
                <Divider />
                <div className={css.loginMessage}>
                  Already have an account? <a href="/login">Log in here</a>.
                </div>
                <a
                  className={css.redirect}
                  href="https://interpressecurity.com"
                  target="_blank"
                >
                  Visit Website <RedirectIcon />
                </a>
              </div>
            </form>
          ) : (
            <div className="p-8 text-center">
              <h3 className="text-xl font-semibold text-green-600">
                Free Trial Successfully Requested!
              </h3>
              <p className="mt-2">
                Look out for an email from @interpressecurity.com with more
                information!
              </p>
            </div>
          )}
        </div>
        <div className={css.right}>
          <div className={css.rightContent}>
            <h3 className={css.rightTitle}>
              Optimize Your Defenses Against the Threats That Matter Most
            </h3>
            <div className={css.rightParagraph}>
              Ensure your cyber defenses are optimized and ready to defend
              against the most relevant security threats targeting your
              organization.
            </div>
            <img
              src="/screenshot.png"
              alt="Screenshot"
              className={css.screenshot}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default FreeTrial;
