import { ServerError } from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import { NavigateFunction } from 'react-router-dom';
const IGNORED_QUERIES = ['GlobalIndustries'];

const MAX_RETRY_COUNT = 3;

export const retryLink = (navigate: NavigateFunction, refreshToken: any) =>
  new RetryLink({
    delay: {
      initial: 400,
      max: Infinity,
      jitter: true
    },
    attempts: (count, operation, error) => {
      const operationName = operation.operationName;

      if (count > MAX_RETRY_COUNT) {
        if (
          (error as ServerError).statusCode === 401 &&
          !IGNORED_QUERIES.includes(operationName)
        ) {
          navigate('/auth/logout');
        }
        return false;
      }

      refreshToken();
      return true;
    }
  });
