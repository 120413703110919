import { FC, PropsWithChildren } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { Loader } from 'shared/elements/Loader';
import { useAuth } from './AuthContext';

const paths = [
  '',
  '/',
  '/login',
  '/logout',
  '/404',
  '/auth/logout',
  '/free-trial'
];
export const PREV_ROUTE = 'PREV_ROUTE';

export const AuthRoute: FC<PropsWithChildren> = () => {
  const { isAuthenticated, isLoading, user, refreshToken } = useAuth();
  const location = useLocation();
  const pathname = location.pathname;

  // Allow free trial access without authentication
  if (pathname === '/free-trial') {
    return <Outlet />;
  }

  // call refresh, but this will only update if needed
  if (!isLoading && !paths.includes(pathname)) {
    refreshToken();
  }

  const prevRoute = window.localStorage.getItem(PREV_ROUTE);

  if (!isAuthenticated && !isLoading) {
    if (!prevRoute && pathname && !paths.includes(pathname)) {
      window.localStorage.setItem(PREV_ROUTE, pathname);
    }

    return <Navigate to="/login" replace />;
  }

  if (isLoading) {
    return <Loader />;
  } else {
    const hasUser = Object.keys(user || {}).length > 0;
    if (hasUser && prevRoute && pathname !== prevRoute) {
      window.localStorage.removeItem(PREV_ROUTE);
      return <Navigate to={prevRoute} />;
    }
  }

  return <Outlet />;
};
