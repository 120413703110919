import { ReactComponent as PageLeftArrowIcon } from 'assets/icons/pageLeftArrow.svg';
import { ReactComponent as PageRightArrowIcon } from 'assets/icons/pageRightArrow.svg';
import classNames from 'classnames';
import { MotionGroup, MotionItem } from 'reablocks';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import PriorityIcon from 'shared/icons/PriorityIcon';
import useWindowDimensions from 'shared/utils/useWindowDimensions';
import css from './AttackMatrix.module.css';

export const TACTIC_NAMES = [
  'Collection',
  'Command and Control',
  'Credential Access',
  'Defense Evasion',
  'Discovery',
  'Execution',
  'Exfiltration',
  'Impact',
  'Initial Access',
  'Lateral Movement',
  'Persistence',
  'Privilege Escalation',
  'Reconnaissance',
  'Resource Development'
];

const getColorClass = coverageValue => {
  if (coverageValue >= 66) return 'border-l-purple500';
  if (coverageValue >= 34) return 'border-l-blue500';
  return 'border-l-gray300';
};

/** A single technique in the matrix */
const Technique = ({ selectedCoverage, technique }) => {
  const coverageValue = technique[selectedCoverage || 'content'];

  return (
    <div key={technique.id} className={css.technique}>
      <div
        className={classNames(
          css.leftBorder,
          'border-l-sm border-solid',
          getColorClass(coverageValue)
        )}
      >
        <Link className={css.name} to={`/techniques/${technique.mitreId}`}>
          {technique.name}
        </Link>
        <div className={css.priority}>
          <PriorityIcon priority={technique.priority} /> {technique.priority}
        </div>
      </div>
    </div>
  );
};

/** Renders the Attack Matrix (the heart of the page) */
const AttackMatrix = ({
  selectedCoverage,
  techniques,
  visibleTactics = TACTIC_NAMES
}: {
  selectedCoverage: string;
  techniques: any[];
  visibleTactics?: string[] | string;
}) => {
  const [columnIndex, setColumnIndex] = useState(0);
  const windowSize = useWindowDimensions();

  // Process visibleTactics to ensure consistent format (always array of strings)
  let normalizedVisibleTactics: string[] = TACTIC_NAMES;

  if (Array.isArray(visibleTactics)) {
    normalizedVisibleTactics = visibleTactics;
  } else if (typeof visibleTactics === 'string') {
    normalizedVisibleTactics = visibleTactics.split(',');
  }

  // Only use tactic names that are in both the visible tactics array and the TACTIC_NAMES array
  const filteredTacticNames =
    normalizedVisibleTactics.length > 0
      ? TACTIC_NAMES.filter(tactic => normalizedVisibleTactics.includes(tactic))
      : TACTIC_NAMES;

  // Figure out how many columns we can fit: space - arrow space, divided by 180
  const columnCount = Math.floor((windowSize.width - 189) / 180);
  const lastColumnIndex = columnIndex + columnCount;
  const showLeftArrow = columnIndex > 0;
  const showRightArrow =
    filteredTacticNames.length - columnCount - columnIndex > 0;

  // Reset column index if it's out of bounds after filtering
  if (
    columnIndex > filteredTacticNames.length - columnCount &&
    filteredTacticNames.length > columnCount
  ) {
    setColumnIndex(filteredTacticNames.length - columnCount);
  }

  // Group techniques by tactic
  const techniquesByTactic = techniques.reduce((byTactic, technique) => {
    for (const tacticName of technique.tacticNames) {
      byTactic[tacticName] ||= [];
      byTactic[tacticName].push(technique);
    }
    return byTactic;
  }, {});

  const shiftColumnsLeft = () => setColumnIndex(Math.max(columnIndex - 1, 0));
  const shiftColumnsRight = () =>
    setColumnIndex(Math.min(columnIndex + 1, filteredTacticNames.length));
  return (
    <div className={css.matrix}>
      <div className={css.divider}>
        <div className={css.dividerBox} />
        <div className={css.dividerLine} />
        <div className={css.dividerBox} />
      </div>
      {showLeftArrow && (
        <div className={css.paginationArrow}>
          <PageLeftArrowIcon onClick={shiftColumnsLeft} />
        </div>
      )}
      {filteredTacticNames.map(
        (tactic, i) =>
          i >= columnIndex &&
          i < lastColumnIndex && (
            <div key={tactic}>
              <div className={css.header}>{tactic}</div>
              <MotionGroup>
                <div className={css.count}>
                  {techniquesByTactic[tactic]?.length} Techniques
                </div>
                {techniquesByTactic[tactic]?.map(technique => (
                  <MotionItem key={technique.id}>
                    <Technique {...{ selectedCoverage, technique }} />
                  </MotionItem>
                ))}
              </MotionGroup>
            </div>
          )
      )}
      {!!showRightArrow && (
        <div className={css.paginationArrow}>
          <PageRightArrowIcon onClick={shiftColumnsRight} />
        </div>
      )}
    </div>
  );
};

export default AttackMatrix;
