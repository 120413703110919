import isArray from 'lodash/isArray';
import isBoolean from 'lodash/isBoolean';
import isString from 'lodash/isString';
import isObject from 'lodash/isObject';
import isUndefined from 'lodash/isUndefined';
import assert from 'shared/utils/assert';
import isEqual from 'lodash/isEqual';
import { FilterConfig } from './FiltersConfig';
import { filter } from 'lodash';

export type FilterValue = string[] | [number, number] | boolean | object | null;

export interface Filter {
  field?: string; // the property on the data object used to filter
  label: string; // the human-friendly label we'll display
  icon: any; // FC?
  // TODO: Someday (after we upgrade svgr?) we might have a distinct type for
  //       our SVG imports; if so, use it here
  options?: string[];
  type: 'checkbox'; // | 'toggle' | 'checkbox dropdown', etc.
}

/**
 * Applies the default values for every filter field, unless the field already
 * has a (non-default) value.
 * @type T should be the filter object type (eg. ie. the DefaultValues type)
 */
export const applyFilterDefaults = <T,>(filters, defaultFilterValues): T =>
  Object.keys(defaultFilterValues).reduce(
    (filterValues, fieldName) => ({
      ...filterValues,
      [fieldName]: filters?.[fieldName] ?? defaultFilterValues[fieldName]
    }),
    {} as T
  );

// NOTE: Only exported for testing
export const assertFiltersAreDeserialized = (filterValues = {}) => {
  for (const [field, value] of Object.entries(filterValues)) {
    assert(
      isBoolean(value) ||
        isArray(value) ||
        isObject(value) ||
        isUndefined(value),
      `Deserialized values should be booleans/strings/objects, but ${field} was ` +
        `${JSON.stringify(value)}.`
    );
  }
};

// NOTE: Only exported for testing
export const assertFiltersAreSerialized = (filterValues = {}) => {
  for (const [field, value] of Object.entries(filterValues)) {
    assert(
      isBoolean(value) || /*isArray(value) ||*/ isString(value),
      `Serialized values should be booleans/arrays, but ${field} was ` +
        `${JSON.stringify(value)}.`
    );
  }
};

/**
 * Given a set of filter values (and their defaults), and a field name, this
 * function extracts that field's value and returns a deserialized version of it
 *
 * NOTE: The defaults are only used to type the field; we don't actually apply
 *       default values here.
 * @example deserializeFilterValue('a', {a: '1,2'}, {}) would result in [1,2]
 */
export const deserializeFilterValue = <T,>(
  value,
  defaultValue,
  filterConfig: FilterConfig
) => {
  if (filterConfig.type === 'andOrCheckboxDropdown') {
    if (isUndefined(value) || isEqual(value, defaultValue)) {
      return defaultValue?.[filterConfig.field];
    }

    // This value is coming from the URL params
    return {
      ...value,
      [filterConfig.valuesKey]: value?.[filterConfig.valuesKey].split(',')
    };
  }

  if (isUndefined(value)) {
    return defaultValue ?? [];
  }
  // Otherwise, parse the value ...

  if (isBoolean(defaultValue)) {
    // If we have a boolean default value, that means this field is boolean, and
    // so  we don't need to serialize it further (booleanas are booleans
    // in both the serialized and deserialized versions)
    return value;
  }

  // Determine if it's a min/max value (ie. a slider field) from its default
  if (defaultValue?.[0] === 0 && defaultValue?.[1] === 100) {
    // We only expect two values (min and max) in our numeric arrays
    return (
      value
        ?.split(',', 2)
        ?.filter(x => !isUndefined(x) && x !== '')
        ?.map(Number) ?? [0, 100]
    );
  }
  if (isString(value)) {
    return value?.split(',')?.filter(x => !isUndefined(x) && x !== '');
  }
  if (isArray(value) && !value.length) {
    return [];
  }

  return value;
};

/**
 * Extracts the values for all filter fields and parses or "deserializes" them,
 * converting any strings values in them into parsed arrays.
 */
// export const deserializeFilterValues = <T,>(
//   filterValues,
//   defaultFilterValues
// ): T => {
//   assertFiltersAreSerialized(filterValues);
//   assert(defaultFilterValues, 'Defaults are required to determine field types');

//   // @ts-ignore the generic isn't working for some reason
//   return Object.keys(defaultFilterValues).reduce((deserialized, fieldName) => {
//     // Extract the value and default value
//     const value = filterValues?.[fieldName];
//     const defaultValue = defaultFilterValues[fieldName] ?? [];

//     const deserializedValue = deserializeFilterValue<T>(value, defaultValue);
//     return { ...deserialized, [fieldName]: deserializedValue } as T;
//   }, {}) as T;
// };

/**
 * This is almost the same as deserializeFilterValues, except it expects that
 * some values may already be deserialized, and doesn't freak out if it
 * encounters them (it just leaves them unchanged).
 */
export const deserializeFilterValuesIfNecessary = <T,>(
  filterValues,
  defaultFilterValues
): T => {
  assert(defaultFilterValues, 'Defaults are required to determine field types');
  const filterFields = Object.keys(defaultFilterValues);
  const uiFilterConfigs =
    defaultFilterValues && typeof defaultFilterValues.filters === 'function'
      ? defaultFilterValues.filters()
      : [];

  const deserialized = filterFields.reduce((filters, fieldName) => {
    const value = filterValues[fieldName];
    const defaultValue = defaultFilterValues[fieldName];
    const filterConfig = uiFilterConfigs.find(
      config => config.field === fieldName
    );

    let fieldValue = value;
    try {
      fieldValue = deserializeFilterValue(value, defaultValue, filterConfig);
    } catch {
      // Do nothing; field value is still the original value
    }
    return { ...filters, [fieldName]: fieldValue ?? defaultValue };
  }, {});
  assertFiltersAreDeserialized(deserialized);
  return deserialized as T;
};

/**
 * Similar to applyFilterDefaults, but it also serializes the values/defaults
 */
export const serializeAndApplyDefaults = <T,>(filters, filtersConfig): T => {
  assertFiltersAreDeserialized(filters);
  return filtersConfig.filters().reduce((filterValues, filterConfig) => {
    const fieldName = filterConfig.field;
    const value = filters?.[fieldName];
    const defaultValue = filtersConfig[fieldName];
    const serializedDefault = serializeFilterValue(
      defaultValue,
      defaultValue,
      filterConfig
    );
    const serializedValue = serializeFilterValue(
      value,
      defaultValue,
      filterConfig
    );

    return {
      ...filterValues,
      [fieldName]: serializedValue ?? serializedDefault
    };
  }, {} as T);
};

export const serializeFilterValue = (
  filterValue: any,
  defaultValue: any,
  filterConfig: FilterConfig
): string | boolean | string[] | object => {
  const { submitAsArrayOfStrings, field, valuesKey, type } = filterConfig;
  if (isBoolean(defaultValue)) {
    return filterValue;
  } else if (type === 'andOrCheckboxDropdown') {
    let valueToSerialize;

    if (filterValue) {
      valueToSerialize = filterValue;
    } else {
      valueToSerialize = defaultValue;
    }

    const useOr = valueToSerialize.useOr;

    if (isObject(valueToSerialize) && !valueToSerialize?.[field]) {
      if (isArray(valueToSerialize?.[valuesKey])) {
        return {
          [valuesKey]: valueToSerialize?.[valuesKey].join(','),
          useOr: useOr
        };
      }
      return {
        [valuesKey]: valueToSerialize?.[valuesKey],
        useOr: useOr
      };
    }
    return {
      [valuesKey]: valueToSerialize?.[field]?.[valuesKey].join(','),
      useOr: useOr
    };
  } else if (defaultValue?.[0] === 0 && defaultValue[1] === 100) {
    // It's a min/max (slider) field
    return filterValue?.join(',');
  } else if (submitAsArrayOfStrings) {
    // If's an enum, just return as is
    return filterValue;
  } else {
    // If it's not a min/max array (ie. [0, 100]) or a boolean, toString it
    return isUndefined(filterValue) ? '' : filterValue + '';
  }
};

/**
 * Builds an object with all the filters, used to set the URL parameters
 */
export const serializeFilterValues = (
  filterValues: any,
  defaultFilterValues: any
) => {
  // when we come here from the container after apply filters, the filter values
  // are (mostly) not serial, eg.
  // content: "0,100" , integrationNames: "", limitCampaigns: true, ...
  assertFiltersAreDeserialized(filterValues);
  assert(defaultFilterValues, 'Defaults are required to determine field types');

  return defaultFilterValues.filters().reduce((filters, filterConfig) => {
    const value = filterValues[filterConfig.field];
    const defaultValue = defaultFilterValues[filterConfig.field] ?? [];

    const fieldValue = serializeFilterValue(value, defaultValue, filterConfig);
    return { ...filters, [filterConfig.field]: fieldValue ?? defaultValue };
  }, {});
};

/**
 * When we get slider values they come as strings, so we need to split them
 * and convert the parts to numbers ... unless their is no value, in which case
 * we want to return an array of the min/max values.
 */
export const splitSliderValue = (
  value: string,
  min: number = 0,
  max: number = 100
): [number, number] =>
  // NOTE: This must be a ternary, not a || or ??, because ''.split is non-null
  value
    ? (value?.split(',', 2)?.map(x => Number(x)) as [number, number])
    : [min, max];

/**
 * This is almost the same as deserializeFilterValues, except it expects that
 * some values may already be deserialized, and doesn't freak out if it
 * encounters them (it just leaves them unchanged).
 */
// export const serializeFilterValuesIfNecessary = (
//   filterValues,
//   defaultFilterValues
// ) => {
//   // when we come here from the container after apply filters, the filter values
//   // are (mostly) not serial, eg.
//   // content: "0,100" , integrationNames: "", limitCampaigns: true, ...
//   assertFiltersAreDeserialized(filterValues);
//   assert(defaultFilterValues, 'Defaults are required to determine field types');

//   const filterFields = Object.keys(defaultFilterValues);
//   return filterFields.reduce((filters, fieldName) => {
//     const value = filterValues[fieldName];
//     const defaultValue = defaultFilterValues[fieldName] ?? [];

//     let fieldValue;
//     try {
//       fieldValue = serializeFilterValue(value, defaultValue);
//     } catch (err) {
//       fieldValue = value;
//     }
//     return { ...filters, [fieldName]: fieldValue ?? defaultValue };
//   }, {});
// };
