import { gql, useMutation } from '@apollo/client';
import { FC } from 'react';
import FreeTrial from './FreeTrial';

const CLIENT_MUTATION = gql`
  mutation GetClient($input: ClientInput!) {
    getClient(input: $input)
      @rest(type: "Client", path: "client", method: "POST") {
      tenant_id
      client_id
    }
  }
`;

interface GetClientIdResult {
  getClient: {
    tenant_id: string;
    client_id: string;
  };
}

interface GetClientIdInputs {
  input: {
    email: string;
    fullName: string;
  };
}

export const FreeTrialContainer: FC = () => {
  const [getClientId] = useMutation<GetClientIdResult, GetClientIdInputs>(
    CLIENT_MUTATION
  );

  const onDiscover = async ({ email, fullName }) => {
    const { data } = await getClientId({
      variables: {
        input: {
          email,
          fullName
        }
      }
    });
    if (data.getClient === null) {
      // Should clear the form and pop up a warning here.
      window.location.href = '/free-trial';
    }
    // Set the client ID in local storage and update fusion auth sdk
    localStorage.setItem('@interpres:clientid', data.getClient.client_id);
    // Small timeout to allow the local storage to save before redirect
    return new Promise<void>(resolve =>
      setTimeout(() => {
        // login({ clientId: data.getClient.client_id, email });
        resolve();
      }, 200)
    );
  };

  return <FreeTrial onDiscover={onDiscover} />;
};
